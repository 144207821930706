<template>
	<div class="page">
		<sysmenu></sysmenu>


		<div class="rightcontent">
			<router-view />
		</div>

	</div>
</template>

<script>
import sysmenu from './menu/menu.vue'

export default {
	components: {
		sysmenu


	},
	data() {
		return {
			showmenu: 1,
			mainbg: ""
		}
	},
	mounted() {
		if(window.sessionStorage.getItem("username")){

		}else{
			window.location.href="/#/login"
		}
	},
	methods: {
		hideMenu() {
			if (this.$store.state.leftmenu) {
				this.$store.dispatch("showSmenu", 0)
			} else {
				this.$store.dispatch("showSmenu", 1)
			}

		},

		getConfig() {
			if (localStorage.getItem("config")) {
				let config = JSON.parse(localStorage.getItem("config"))
				this.$store.dispatch("setConfig", config)
				// if(config.favico){
				// 	var link = document.createElement('link');
				// 	link.type = 'image/x-icon';
				// 	link.rel = 'shortcut icon';
				// 	link.href = config.favico;
				// 	document.getElementsByTagName('head')[0].appendChild(link);
				// }
				// if (config.schoolname) {
				// 	document.title = "一站式办事大厅-"+config.schoolname
				// }
			}
			this.$http.post("api/sch_configs").then(res => {
				let config = res.data
				// for (let k of res.data) {

				// 	switch (k.ktype) {
				// 		case 'logo':
				// 			config.logo = k.kvalue
				// 			break;
				// 		case 'leftlogo':
				// 			config.leftlogo = k.kvalue
				// 			break;
				// 		case 'mainbg':
				// 			config.mainbg = k.kvalue
				// 			break;
				// 		case 'schoolname':
				// 			config.schoolname = k.kvalue
				// 			break;
				// 		case 'loginbg':
				// 			config.loginbg = k.kvalue
				// 			break;
				// 		case 'favico':
				// 			config.favico = k.kvalue
				// 			break;
				// 	}

				// }
				// if(config.favico){
				// 	var link = document.createElement('link');
				// 	link.type = 'image/x-icon';
				// 	link.rel = 'shortcut icon';
				// 	link.href = config.favico;
				// 	document.getElementsByTagName('head')[0].appendChild(link);
				// }
				// if (config.schoolname) {
				// 	document.title = "一站式办事大厅-"+config.schoolname
				// }

				this.$store.dispatch("setConfig", config)
				localStorage.setItem("config", JSON.stringify(config))




			})
		},

	}
}
</script>
<style scoped>
.page {
	position: relative;
	overflow: hidden;
	background-size: cover;
}

.rightcontent {
	border-top: 56px solid transparent;
	height: 100%;
	box-sizing: border-box;
	transition: margin .3s;
	margin-left: 182px;
	margin-right: 0%;
	border-radius: 4px;
	border-left: 1px solid #F6F6F6;
	overflow: auto;

}

.expand {
	margin-left: 1%;

}

.rightpage {

	/*padding: 10px;*/
	background-color: #fafbfc;
	height: 100%;
	overflow: auto;
	box-sizing: border-box;
	border-radius: 4px;
	padding-left: 40px;
	padding-right: 10px;

}

.expand2 {
	padding-left: 10px;
}

.expandbtn {

	width: 50px;
	height: 0;
	border-top: 20px solid rgba(118, 118, 118, 0.3);
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	position: absolute;
	top: 48%;
	left: 166px;
	color: #f6f6f6;
	transition: all 0.2s;
	transform-origin: top center;
	transform: rotateZ(-90deg) scaleY(1);
	cursor: pointer;
	z-index: 3;

}

.hideexp {
	left: -20px;
}

.btnarrow {
	position: absolute;
	top: -20px;
	left: 16px;
}

.expandbtn:hover {
	border-top: 20px solid rgba(118, 118, 118, 0.6);
	transform: rotateZ(-90deg) scaleY(1.1);
	color: #ccc;

}
</style>
