import Vue from 'vue'
import VueRouter from 'vue-router'
import indexframe from '../views/index.vue'
Vue.use(VueRouter)

const routes = [{
		path: '/index',
		name: 'indexframe',
		component: indexframe,
		children: [{
				path: '/score',
				component: resolve => require(['../views/pages/score'], resolve)
			},
			{
				path: '/luqu',
				component: resolve => require(['../views/pages/luqu'], resolve)
			},
			{
				path: '/kaofen',
				component: resolve => require(['../views/pages/kaofen'], resolve)
			},
			{
				path: '/videos',
				component: resolve => require(['../views/pages/videos'], resolve)
			},
			{
				path: '/teachers',
				component: resolve => require(['../views/pages/users'], resolve)
			}


		],
	},
	{
		path: '/lnfs',
		component: resolve => require(['../views/frame/lnfs'], resolve)
	},
	{
		path: '/lqcx',
		component: resolve => require(['../views/frame/lqcx'], resolve)
	},
	{
		path: '/cxlq',
		component: resolve => require(['../views/frame/cxlq'], resolve)
	},
	{
		path: '/kfcx',
		component: resolve => require(['../views/frame/kfcx'], resolve)
	},
	{
		path: '/login',
		component: resolve => require(['../views/login'], resolve)
	},
	{
		path: '*',
		redirect:"/login"
	}
]




let router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function(name) {
	var params = [];
	var value = null;
	if (window.location.href.indexOf(name + "=") >= 0) {
		let q = window.location.href.split("?")[1];
		if (q.indexOf("#") > 0) {
			q = q.split("#")[0];
		}
		params = q.split("&");
		for (let p of params) {
			if (p.indexOf(name + "=") >= 0) {
				value = p.replace(name + "=", "").replace(/\//g, "")
				break;
			}
		}
	}
	if (value && value != undefined && value != "undefined") {
		return value
	} else {
		return "";
	}
}



export default router