<template>
	<div>
		<div class="header">
			<div class="menubar">
				<img :src="'/img/logo.png'" style="height: 100%; vertical-align: middle;" />
				<span
					style="display: inline-block; vertical-align: middle; color:aliceblue; font-size: 22px; margin-left: 20px;">江苏省第二师范录取查询系统</span>

				<div class="logoutbox" @click="logout">{{userinfo.real_name}}：退出&nbsp;&nbsp;&nbsp;&nbsp;<i class="el-icon-arrow-down"></i></div>
			</div>


		</div>
		<div class="menuline"></div>



		<div :class="['leftmenu'] ">
			<div style="width: 140px; margin: 0 auto; height: 100%;">
				<div style="height: 70px;"></div>

				<div class="smenubox" style=" border-radius: 6px;">
					<div :class="['menuitem',nowsMenu==item.id?'menuckd':'']" v-for="(item,idx) in Smenus" :key="idx"
						@click="goPage(item)">
						<i :class="item.icon"></i> {{item.title}}
						<div class="ckdtail">

						</div>
					</div>
				</div>
			</div>
		</div>





	</div>
</template>

<script>
	export default {
		data() {
			return {

				avatar: "",
				Menus: [],
				Smenus: [],
				nowbMenu: 0,
				nowsMenu: "",
				userinfo: {
					real_name: sessionStorage.getItem("realName"),
					pmid: 0
				},
				isShowModal: false,
				isShowYearModal: false,
				ltmenuHide: true,
				pwdForm: {
					oldPwd: "",
					newPwd1: "",
					newPwd2: "",
				},
				weather: {},
			};
		},
		mounted() {
			let Smenus = []


			if (sessionStorage.getItem("username") == "admin") {
				
				Smenus = [{
					id: 11,
					icon: "",
					title: "历年分数",
					path: "/score"
				}, {
					id: 1,
					icon: "",
					title: "录取查询",
					path: "/luqu"
				}, {
					id: 2,
					icon: "",
					title: "分数查询",
					path: "/kaofen"
				}, {
					id: 3,
					icon: "",
					title: "首页视频",
					path: "/videos"
				}, 
				{
					id: 4,
					icon: "",
					title: "教师管理",
					path: "/teachers"
				}]
			
			}else{
				Smenus = [ {
					id: 1,
					icon: "",
					title: "录取查询",
					path: "/luqu"
				}, ]
			}


			this.Smenus = Smenus
			//this.getRoleMenu();
			for (let item of Smenus) {
				if (this.$route.path == item.path) {
					this.nowsMenu = item.id
				}
			}


		},

		methods: {
			logout() {
				window.sessionStorage.clear()
				this.$router.push("/login")
			},
			getRoleMenu() {

				this.$http.post("/api/leave_teacher_menu").then(res => {
					this.Menus = res.data
					//this.getConfig();
					this.getTeacherInfo();
					for (let item of this.Menus) {
						if (item.path && this.$route.path.indexOf(item.path) >= 0) {
							this.nowsMenu = 0
							this.nowbMenu = item.id
							this.$store.dispatch("showSmenu", 0)
						} else {
							for (let sitem of item.children) {
								if (sitem.path && this.$route.path.indexOf(sitem.path) >= 0) {
									this.nowsMenu = sitem.id
									this.nowbMenu = item.id
									this.Smenus = item.children;
									this.$store.dispatch("showSmenu", 1)
									break
								}
							}
						}
					}
				})
			},
			goBMenu(item) {
				this.Smenus = item.children;
				this.nowbMenu = item.id;
				if (this.Smenus && this.Smenus.length > 0) {
					this.goPage(this.Smenus[0]);
					this.nowsMenu = this.Smenus[0].id;
					this.$store.dispatch("showSmenu", 1)
				} else if (item.path) {
					if (this.$route.path != item.path) {
						this.$router.push(item.path);
					}
					this.$store.dispatch("showSmenu", 0)
				}
			},
			goPage(item) {
				if (this.$route.path != item.path) {
					this.$router.push(item.path)
					this.nowsMenu = item.id
				}
			},
			setYear() {
				localStorage.setItem("year", this.year);
				window.location.reload();
			},
			handleCommand(e) {
				console.log(e);
				if (e == "updPwd") {
					this.isShowModal = true;
				} else if (e == "logout") {
					localStorage.clear();
					sessionStorage.clear();
					this.$router.push("/login");
				} else if (e == "changeYear") {
					this.isShowYearModal = true;
				}
			},
			savePwd() {
				if (!this.pwdForm.oldPwd) {
					this.$message.error("请输入原密码");
					return;
				}

				if (!this.pwdForm.newPwd1) {
					this.$message.error("请输入新密码");
					return;
				}
				if (!this.pwdForm.newPwd2) {
					this.$message.error("请再次输入新密码");
					return;
				}
				if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
					this.$message.error("两次密码不一致");
					return;
				}
				this.$http.post("/api/reset_myself_pwd", this.pwdForm).then((ok) => {
					if (ok.data.code) {
						if (ok.data.code == 500) {
							this.$message.error(ok.data.msg);
						} else {
							this.$message.error("保存失败");
						}
					} else {
						this.$message.success("修改成功");

						this.isShowModal = false;
						this.$router.push("/login");
					}
				});
			},
			getConfig() {
				this.$http.post("/api/sch_configs").then(res => {
					for (let k of res.data) {

						if (k.ktype == 'logo2') {
							//this.leftLogo = k.kvalue
						}
					}


				})
			},
			getTeacherInfo() {

				this.$http.post("/api/get_teacher_info").then(res => {
					this.avatar = res.data.avatar
					this.userName = res.data.username
					this.$store.dispatch("setTeacher", res.data)
				})
			},

		},
	};
</script>
<style scoped lang="less" type="text/less">
	.moudelname{float: left; margin-top: 20px; margin-left: 20px;}
	
	
.leftmenu {
  width: 170px;
  height: 100%;
  position: absolute;
  
  transition: all .3s;
  transform-origin: left;
 

  .menuitem {
    padding: 10px;
    text-align: left;

    cursor: pointer;
    margin: 0 auto;

	font-size: 14px;
	background-color: #fff;
	border-bottom: 1px solid #eee;
	border-radius: 4px;
  }
.menuitem:hover{ background-color: #eee;}
  // .menuitem:first-child {
  //   border-top-left-radius: 4px;
  //   border-top-right-radius: 4px;
  // }

  // .menuitem:last-child {
  //   border-bottom: 1px solid #0794b3;
  //   border-bottom-left-radius: 4px;
  //   border-bottom-right-radius: 4px;
  //   border-radius: 4px;
  // }

  .menuckd {
     background-color: #b9cbdf !important;
  }
  .menuckd:hover{ background-color: #b9cbdf;}
}
.menuline{ height: 2px; border: 2px solid #b9cbdf; position: absolute; top: 50px; width: 100%; background-color: #e1e9f9;}
.hidemenu {
  transform: scale(0, 1);
}

.smenubox {
		width: 100%;
		
		margin: 0 auto;
		background-color: #fff;
		height: 86%;
	
	}

	.hidemenu {
		transform: scale(0, 1);
	}

	.menuitem {
		padding: 10px;
		text-align: left;
		cursor: pointer;
		margin: 0 auto;

		color: #333333;

	}

	.menuckd {

		color: #1890ff;
		box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2) inset;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		position: relative;
		background-color: #fafbfc;
	}

	// .menuckd .ckdtail {
	// 	width: 40px;
	// 	height: 80px;
	// 	position: absolute;
	// 	top: -20px;
	// 	right: -5px;
	// 	// background-image: url(//aiwx.org.cn/static/images/tail.png);
	// 	background-size: auto 100%;

	// 	background-repeat: no-repeat;
	// 	background-color: #fafbfc;



	// }

.header {
  height: 50px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
	background-color: #fff;

  .menubar {
    width: 100%;
    height: 100%;
    float: left;
    color: #000;
    text-align: left;
    line-height: 50px;
	font-size: 30px;
	background-color: #6f95f8;

    img {
      height: 90%;
      margin-top: 2px;
    }
  }

  .headright {
    height: 100%;
    margin-left: 200px;
	
    position: relative;
	text-align: center;

    .bigmenu {
     
      height: 40px;
	  padding-top: 12px;
      color: #626874;
      .bmitem {
        height: 100%;
        padding: 5px 40px 0px 40px;
        border-radius: 4px;
        cursor: pointer;
		display: inline-block;
		vertical-align: top;
		
		margin-right: 10px;
	
      }

      .bmckd {
        
		background-image: url(//aiwx.org.cn/static/images/menubg.png);
		background-size: 100% 100%;
		line-height: 50px;
		padding-top: 0;
		color: #409efe;
		font-weight: bolder;
      }
    }

    .nickname {
      position: absolute;
      right: 60px;
      //top: 10px;
      height: 100%;
      line-height: 100%;
      color: #fff;
      padding: 0 20px;
      border-radius: 4px;
    }

    .headimg {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 20px;
      border: 1px solid  #0794b3;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
.logoutbox{
	width: 250px;
	height: auto;
	color:#fff;
	float: right;
	font-size: 14px;
	cursor: pointer;
	text-align: right;
	padding-right: 10px;
}
</style>