import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false
import axios from "axios"
Vue.prototype.$http = axios
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);






axios.interceptors.request.use(req => {
	
	if (req.url.indexOf("http") >= 0) {

	} else {
		 req.url = "https://desfapi.aiwx.org.cn" + req.url

		// req.url = "http://127.0.0.1:12345" + req.url
		

	}
	return req
}, error => {
	return Promise.reject(error)
});




Vue.prototype.uploadTemp = (fileObj) => {
	console.log("初始化")
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1);
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI0Dd9MsOqnVoj',
		signature: 'Xst4hA4qiPYO5HXG/8CcQgDTm9U=',
		success_action_status: "200",

	};
	var host = 'https://mmtemp.oss-cn-hangzhou.aliyuncs.com';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {


			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
				.indexOf(
					".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function(theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function() {
						fileObj.onSuccess({
							src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this
								.height,
							fileName: fileObj.file.name
						})

					};
				};

			} else {
				fileObj.onSuccess({
					src: outurl + "/" + fileKey,
					fileName: fileObj.file.name
				})
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}




Vue.prototype.uploadOss = (fileObj) => {
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI4G55gyD65iSg836oWSK4',
		signature: '7a7dfIH8bpoHXTeZxd+3xGS/g54=',
		success_action_status: "200",
		app: "diershifan"

	};
	var host = 'https://oss.aiwx.org.cn/upload';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {
			let resObj = res.currentTarget.responseText
			try {
				resObj = JSON.parse(resObj)
			} catch (e) {
				resObj = {}
			}
			resObj.size = fileObj.file.size;
			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
					.indexOf(
						".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function (theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function () {
						if (resObj.src) {
							resObj.src += "?s=" + this.width + "_" + this.height
						}
						fileObj.onSuccess(resObj)

					};
				};

			} else {
				fileObj.onSuccess(resObj)
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}



Vue.prototype.getQuery = (name) => {
	var n_url = window.location.href;
	n_url = n_url.replace("/#", "");
	if (n_url.indexOf("?") > 0) {
		n_url = n_url.split("?")[1]
	}
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = n_url.match(reg);
	if (r != null) return unescape(r[2]).split("#")[0];
	return null;
}




new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
